html {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

body {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

#root {
  position: relative;
}

body::-webkit-scrollbar {
  width: 0 !important;
  display: none !important;
}

:root {
  --safe-area-t: env(safe-area-inset-top);
  --safe-area-r: env(safe-area-inset-right);
  --safe-area-b: env(safe-area-inset-bottom);
  --safe-area-l: env(safe-area-inset-left);
}

::-webkit-scrollbar {
  width: 0;
  display: none !important;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: transparent;
}

::-webkit-scrollbar-horizontal {
  height: 0
}

#root {
  height: 100%;
  width: 100%;
}

stripe-payment-sheet {
  position: absolute;
  z-index: 10000;
}

pwa-action-sheet, pwa-camera-modal, pwa-camera-modal-instance  {
  z-index: 10000;
}

pwa-action-sheet .content {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

body {
  margin: 0;
  overflow-x: hidden;
  position: relative;
  font-family: -apple-system, 'Open Sans', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
/* Font formats: 'woff', 'woff2', 'truetype, 'opentype','embedded-opentype', and 'svg' */


@font-face {
  font-family: 'Open Sans';
  src: local('Opensans'), url(./assets/fonts/OpenSans-VariableFont_wdth,wght.ttf) format('opentype');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'Inter Tight';
  src: local('Intertight'), url(./assets/fonts/InterTight-VariableFont_wght.ttf) format('opentype');
}

@font-face {
  font-family: 'Italiana';
  src: local('Italiana'), url(./assets/fonts/italiana-regular.otf) format('opentype');
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(./assets/fonts/Montserrat-VariableFont_wght.ttf) format('opentype');
}