.footer-visible {
    transform: translateY(0);
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.5, -0.05, 0.01, 0.99);;
}

.footer-hidden {
    transform: translateY(calc(56px + env(safe-area-inset-bottom)));
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.5, -0.05, 0.01, 0.99);;
}