.topbar-visible {
    transform: translateY(0);
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.5, -0.05, 0.01, 0.99);;
}

.topbar-hidden {
    transform: translateY(calc(-138px - env(safe-area-inset-top)));
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.5, -0.05, 0.01, 0.99);;
}